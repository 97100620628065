.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    /*background-color: #fff; !* Change this as per your color scheme *!*/
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Optional: for a slight shadow under the nav bar */
    border-bottom: 1px solid #3D739C;
    background-color: #FFFFFF; /* Changed from #FFFFFF to #4a7298 */
    height: 70px;
}
.left-section .logo img {
    height: 90px;
    width: auto;
    margin-right: 1rem;
}
.navigation-bar .logo a{
    font-size: 30px;
    color: #ffffff; /* Changed from #3D739C to white for better contrast */
    margin-left: 10px;
    align-items: center;
    text-decoration: none;
}

.navigation-bar .nav-links{
    margin-right: 30px;
    font-size: 30px;
}

.navigation-bar .nav-links a {
    text-decoration: none;
    margin-left: 20px;
    color: #3D739C; /* Changed from #3D739C to #ffffff for better contrast */
    font-size: 16px;
    font-weight: 800;
     /* Change this as per your color scheme */
    /* Additional styles for the links can go here */
    margin-right: 2rem; /* Move the text slightly to the left */
    margin-top: 1rem; /* Move the text slightly down */
}

.navigation-bar .nav-links a:hover {
    color: #e0e0e0; /* Changed from #555 to a light gray for hover effect */
}

.beta-link {
    position: relative;
    padding-right: 12px;
}

.beta-tag {
    position: absolute;
    top: -8px;
    right: -12px;
    background-color: #ffd700;
    color: #000;
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 4px;
    font-weight: bold;
    line-height: 1;
}