.result-container {
    min-width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.navbar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 64px;
}

.main-content {
    padding-top: 64px;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 64px);
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.result-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
}

.llm-agent-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
}

.chat-and-references {
    display: flex;
    flex: 1;
    gap: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    height: calc(100vh - 200px);
}

.chat-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    padding: 0;
    position: relative;
    flex: 2;
    min-width: 600px;
    width: 100%;
}

.references-container {
    flex: 1;
    width: 400px;
    min-width: 400px;
    max-width: 500px;
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.references-container h3 {
    margin: 0;
    padding: 10px 0;
    font-size: 16px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #f0f0f0;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.references-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.reference-item {
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
}

.reference-item:last-child {
    border-bottom: none;
}

.reference-item h4 {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
}

.reference-metadata {
    margin: 4px 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

.reference-authors {
    margin: 4px 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.reference-item a {
    color: #1890ff;
    text-decoration: none;
    font-size: 12px;
}

.reference-item a:hover {
    color: #40a9ff;
}

.message {
    margin: 0.5rem 0;
    padding: 0.8rem;
    border-radius: 8px;
    max-width: 80%;
    white-space: pre-wrap;
    cursor: pointer;
    transition: background-color 0.2s;
}

.message.user {
    background-color: #99c7b1 !important;
    margin-left: auto;
    cursor: default;
    color: black;
}

.message.assistant {
    background-color: #f5f5f5;
    margin-right: auto;
}

.message.assistant:hover {
    background-color: #eeeeee;
}

.message.assistant.selected {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
}

.input-form {
    flex: 1;
    display: flex;
    gap: 1rem;
    background-color: rgba(255, 255, 255, 0);
    margin: 0;
    padding: 0;
    border: none !important;
}

.message-input {
    flex-grow: 1;
    padding: 0.8rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 1rem;
    outline: none;
    background-color: white;
}

.send-button, .clear-button {
    padding: 0.8rem 1.5rem;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.2s ease;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.send-button {
    background-color: #99c7b1 !important;
    color: black;
    border: none;
}

.send-button:hover {
    background-color: #577265;
    color: white;
}

.clear-button:hover {
    background-color: #e0e0e0;
    color: #666;
}

@media (max-width: 1200px) {
    .result-container {
        min-width: 100%;
    }

    .chat-and-references {
        flex-direction: column;
        height: calc(100vh - 160px);
    }

    .chat-container {
        min-width: 100%;
        height: 65vh;
        flex: unset;
    }

    .references-container {
        width: 100%;
        max-width: none;
        min-width: 0;
        height: 25vh;
        flex: unset;
    }

    .messages-container {
        flex: 1;
        overflow-y: auto;
    }
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: rgba(255, 255, 255, 0);
    width: 90%;
    max-width: 800px;
    border-top: none;
    border-bottom: none;
    position: absolute;
    bottom: calc(50% - 220px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    box-shadow: none;
    border-radius: 4px;
}

.chat-container:has(.message-pair) .chat-header {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
    width: 100%;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: 0;
    border-top: 1px solid #e0e0e0;
}

.clear-button {
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    white-space: nowrap;
}

.messages-container {
    visibility: hidden;
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    width: 100%;
    margin-bottom: 0;
}

.chat-container:has(.message-pair) .messages-container {
    visibility: visible;
    margin-bottom: 0;
}

.messages-container::-webkit-scrollbar {
    width: 8px;
}

.messages-container::-webkit-scrollbar-track {
    background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.message-wrapper {
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.message-wrapper.user {
    justify-content: flex-end;
}

.message-wrapper.user .message {
    background-color: #99c7b1 !important;
    color: black;
    border-radius: 8px;
    padding: 8px 16px;
    max-width: 800px;
}

.message-wrapper.user .message:hover {
    background-color: #99c7b1 !important;
}

.message-wrapper.assistant {
    background-color: transparent;
    justify-content: flex-start;
}

.message-wrapper.assistant .message {
    padding: 8px 0;
}

.message {
    padding: 8px 0;
    max-width: 800px;
    width: fit-content;
}

.message.selected {
    border-radius: 6px;
    outline: 1px solid #4096ff;
}

.system-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%;
    object-fit: contain;
    flex-shrink: 0;
    align-self: flex-start;
    border: 1px solid #4B6B8C;
    padding: 2px;
}

.reference-button-wrapper {
    padding: 4px 16px;
    display: flex;
    justify-content: flex-start;
}

.reference-button {
    font-size: 12px;
    padding: 4px 12px;
    height: auto;
    background: transparent;
    border: 1px solid #e0e0e0;
    color: #666;
    display: flex;
    align-items: center;
    gap: 6px;
}

.reference-button:hover {
    background: #f5f5f5;
    border-color: #d0d0d0;
    color: #444;
}

.reference-button.selected {
    background: #e6f4ff;
    border-color: #91caff;
    color: #1677ff;
}

.reference-button .anticon {
    font-size: 14px;
}

.streaming-content {
    white-space: pre-wrap;
    font-family: inherit;
    margin: 0;
}

.message.streaming {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 8px;
    max-width: 100%;
    overflow-wrap: break-word;
}

.message-wrapper {
    display: flex;
    gap: 12px;
    width: 100%;
}

.message-wrapper.assistant {
    margin-left: 0;
}

.system-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.reasoning-section, .response-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden;
}

.reasoning-header, .response-header {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background-color: #f0f2f5;
    border-bottom: 1px solid #e4e6eb;
}

.reasoning-header span, .response-header span {
    margin-left: 8px;
    font-weight: 500;
    color: #1a1a1a;
}

.reasoning-content, .response-content {
    padding: 16px;
    color: #333;
    line-height: 1.5;
}

.system-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.message-wrapper.user {
    justify-content: flex-end;
}

.message-wrapper.user .message {
    background-color: #1890ff;
    color: white;
    border-radius: 16px 16px 0 16px;
    padding: 8px 16px;
    max-width: 80%;
}

.reference-button-wrapper {
    padding: 0 16px 16px;
}

.messages-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    padding: 16px;
    flex-grow: 1;
}

.input-form {
    margin-top: auto;
    padding: 16px;
    border-top: 1px solid #e4e6eb;
}

.message-input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.send-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.message-pair {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.message-pair:last-child {
    margin-bottom: 0;
}

.assistant-response {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.reasoning-section, .response-section {
    margin-bottom: 0;
}

.message-pair {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    width: 100%;
}

.step-item {
    margin: 8px 0;
    line-height: 1.5;
}

.step-item strong {
    color: #666;
    margin-right: 8px;
}

.reasoning-content {
    padding: 12px;
    background: #f9f9f9;
    border-radius: 8px;
    margin-top: 8px;
}

.chat-container > * {
    border-top: none !important;
    border-bottom: none !important;
}

.example-queries {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    height: 100%;
    padding-bottom: 10vh;
    width: 100%;
}

.example-queries-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    margin-top: 5vh;
    margin-bottom: 2rem;
    width: 100%;
}

.example-queries-header .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.example-queries-header .system-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #4B6B8C;
    padding: 3px;
}

.glkb-logo {
    height: 80px;
    width: auto;
    object-fit: contain;
}

.example-queries-header h3 {
    color: #666;
    font-weight: normal;
    margin: 0;
    font-size: 1.1rem;
    max-width: 800px;
    margin-top: 1rem;
}

.example-query-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    overflow-x: hidden;
    padding-bottom: 0;
}

.example-query-list::-webkit-scrollbar,
.example-query-list::-webkit-scrollbar-track,
.example-query-list::-webkit-scrollbar-thumb,
.example-query-list::-webkit-scrollbar-thumb:hover {
    display: none;
}

.example-query {
    flex: 1 1 0;
    width: auto;
    min-width: 0;
    max-width: none;
    margin: 0 0.5rem;
    padding: 1rem 1rem;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
    font-size: 0.9rem;
    color: #333;
    height: auto;
    min-height: 70px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.example-query:hover {
    background-color: #f8f9fa;
    border-color: #d0d0d0;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.example-query:first-child {
    margin-left: 0;
}

.example-query:last-child {
    margin-right: 0;
}

@media (max-width: 700px) {
    .example-query {
        font-size: 0.8rem;
        padding: 0.8rem;
    }
}

.response-content {
    padding: 16px;
    color: #333;
    line-height: 1.5;
}

.response-content p {
    margin-bottom: 1em;
}

.response-content code {
    background-color: #f5f5f5;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
}

.response-content pre {
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
    overflow-x: auto;
}

.response-content blockquote {
    border-left: 4px solid #ddd;
    margin: 0;
    padding-left: 1em;
}

.response-content ul, .response-content ol {
    margin-left: 1.5em;
    margin-bottom: 1em;
}

.response-content h1, 
.response-content h2, 
.response-content h3, 
.response-content h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
}

.chat-container:empty .chat-header {
    margin-top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.chat-container:not(:has(.messages-container > *)) .messages-container {
    flex: 0 0 auto;
}

/* 添加消息操作按钮容器样式 */
.message-actions {
    display: none;
    position: absolute;
    bottom: 8px;
    right: 8px;
    gap: 8px;
}

/* 用户消息和助手消息的操作按钮 */
.message-wrapper:hover .message-actions {
    display: flex;
}

/* 消息操作按钮样式 */
.message-action-button {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    color: #666;
}

.message-action-button:hover {
    background-color: #f0f0f0;
    color: #333;
}

/* 复制按钮特定样式 */
.copy-button, .regenerate-button {
    font-size: 14px;
}

/* 重新生成按钮特定样式 */
.regenerate-button {
    transition: all 0.2s;
}

.regenerate-button:hover {
    color: #1677ff;
}

/* 编辑按钮特定样式 */
.edit-button {
    font-size: 14px;
}

/* 禁用状态的按钮 */
.message-action-button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

/* 编辑状态的消息样式 */
.message-wrapper.editing .message {
    background-color: #f9f9f9;
    border: 1px dashed #ccc;
    padding: 12px;
}

/* 编辑框样式 */
.edit-message-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-size: 1rem;
    font-family: inherit;
    outline: none;
    resize: vertical;
    min-height: 80px;
}

/* 编辑操作按钮容器 */
.edit-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 8px;
}

/* 编辑操作按钮 */
.edit-action-button {
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s;
}

.save-edit-button {
    background-color: #99c7b1;
    color: black;
    border: none;
}

.save-edit-button:hover {
    background-color: #577265;
    color: white;
}

.cancel-edit-button {
    background-color: #f5f5f5;
    color: #666;
    border: 1px solid #e0e0e0;
}

.cancel-edit-button:hover {
    background-color: #e0e0e0;
}

/* 复制成功提示样式 */
.copy-tooltip {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 1100;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.copy-tooltip.show {
    opacity: 1;
} 